import { Component,HostListener, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Response } from '@angular/http/src/static_response'
import { WordService } from "../services/wordService";
import { geoCoordinatesItem } from '../global/utils/geoCoordinates';
import { FormBuilder ,Validators} from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'word-search',
    templateUrl: '../templates/search.component.html',
    providers:[WordService],
    styleUrls: [
        '../css/search.css'
    ]
})

export class WordSearch implements OnInit {
    @Output() public notify: EventEmitter<any> = new EventEmitter<any>();
    //@Output() public words:any = [];
    @Input() public headerIsExpanded:boolean

    public id:String;
    public searchPhrase = "";
    public sugesstedWords:any = [];
    public IsSugesstedWord = false;
    public IsHomoph = false;
    public name = "";
    @Output() public localStyle = {top:'0px'};
    public searchForm = this.fb.group({
        searchPhrase: ["", Validators.nullValidator],
    });
    constructor(private wordService:WordService,
                public fb: FormBuilder,
                private router:Router) {
    }
    public ngOnInit() {
        jQuery(document).scrollTop(0);
    }
    //  closeKeyboard = function(event){
    //     if(event.keyCode == 13)
    //          //code to close the keyboard
    //          var activeElement = <HTMLElement>document.activeElement;
    //         activeElement && activeElement.blur && activeElement.blur();
    // }
    onKey(e) {
        if (e.keyCode == 13) {
            let activeElement = <HTMLElement>document.activeElement;
            activeElement && activeElement.blur && activeElement.blur();

            this.searchPhrase = this.name.trim()
            if (this.searchPhrase != "") {
                this.IsSugesstedWord = false;
                this.IsHomoph = false;
                this.router.navigate(['/search/', this.searchPhrase]);
            }
        }
    }

    public updateSearchPhrase(event) {
    }
    private arrayFromPropOfArray(arrayToSearch:Array<any>,prop:any):Array<any>{
        let array = arrayToSearch.map(function(item) { return item[prop]; });
        return (array.indexOf(undefined) == -1)?array:arrayToSearch;
    }
    public doSearch(event){
        this.searchPhrase = this.name.trim()
        if(this.searchPhrase != "") {
            this.IsSugesstedWord = false;
            this.IsHomoph = false;
            this.router.navigate(['/search/', this.searchPhrase]);
        }
        jQuery(document).scrollTop(0);
    }
    public changeText(value : string){
        if(this.name != "") {
            this.name = value.trim();
            this.IsSugesstedWord = false;
            this.IsHomoph = false;
            this.sugesstedWords = [];
        }
    }

    public onSearchChange(searchValue : string ){
        if(searchValue.trim() == "abuse"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "obtuse"
            this.sugesstedWords[1] = "use"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "bow"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "snow";
            this.sugesstedWords[1] = "wow";
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "close"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "dose"
            this.sugesstedWords[1] = "rose"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "dove"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "stove"
            this.sugesstedWords[1] = "love"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "excuse"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "obtuse"
            this.sugesstedWords[1] = "use"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "expose"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "San Jose"
            this.sugesstedWords[1] = "rose"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "lead"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "head"
            this.sugesstedWords[1] = "plead"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "live"){
            this.sugesstedWords = [];
            this.sugesstedWords[0] = "give"
            this.sugesstedWords[1] = "drive"
            this.IsHomoph = true;
            this.IsSugesstedWord = false;
        }
        else if(searchValue.trim() == "minute"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "minute"
            this.sugesstedWords[1] = "cute"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "misread"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "head"
            this.sugesstedWords[1] = "plead"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "proofread"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "plead"
            this.sugesstedWords[1] = "head"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "read"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "San Jose"
            this.sugesstedWords[1] = "flume"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "resume"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "snow"
            this.sugesstedWords[1] = "wow"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "sewer"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "fewer"
            this.sugesstedWords[1] = "sewer"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "use"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "obtuse"
            this.sugesstedWords[1] = "use"
            this.IsHomoph = true;
        }
        else if(searchValue.trim() == "wind"){
            this.sugesstedWords = [];
            this.IsSugesstedWord = false;
            this.sugesstedWords[0] = "wind"
            this.sugesstedWords[1] = "kind"
            this.IsHomoph = true;
        }
        // else{
        //     this.sugesstedWords = [];
        //     this.IsHomoph = false;
        //     var resultString = "";
        //     if(searchValue.trim().length > 0){
        //         let result = this.wordService.getSuggestion(searchValue.trim(),true).subscribe(
        //             res => {
        //                 resultString = res._body;
        //                 resultString = resultString.substring(1, resultString.length-1);
        //                 var resss = resultString.split(",");
        //                 if(this.IsHomoph == false && this.name != ""){
        //                     this.sugesstedWords = resss;
        //                     this.IsSugesstedWord = true;
        //                     this.IsHomoph = false;
        //                 }
        //             },
        //             err => {
        //                 this.sugesstedWords = [];
        //                 this.IsSugesstedWord = false;
        //                 this.IsHomoph = false;
        //             }
        //         );
        //     }
        //     else{
        //         this.sugesstedWords = [];
        //         this.IsSugesstedWord = false;
        //         this.IsHomoph = false;
        //     }
        // }
    }
}
