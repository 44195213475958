export class userItem {
    constructor(
        public firstName: string | null,
        public lastName: string | null,
        public username: string | null,
        public password: string | null
    ) { }
    public toString() {
        return JSON.stringify(this);
    }
}