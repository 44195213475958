import {
	Component,
	EventEmitter,
	OnInit,
	AfterViewInit,
	Output,
} from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { ScrollSpyAffixDirective } from "ngx-scrollspy";

@Component({
	selector: "header",
	templateUrl: "../../templates/global/header.html",
	styleUrls: ["../../css/header.css"],
	providers: [ScrollSpyAffixDirective],
})
export class header implements OnInit, AfterViewInit {
	public isExpanded = true;
	private _router: Router;

	constructor(public router: Router, public activeRoute: ActivatedRoute) {
		this.isExpanded =
			document.location.href.indexOf("signin") == -1 &&
			document.location.href.indexOf("signup") == -1;
	}
	private arrayFromPropOfArray(
		arrayToSearch: Array<any>,
		prop: any
	): Array<any> {
		let array = arrayToSearch.map(function (item) {
			return item[prop];
		});
		return array.indexOf(undefined) == -1 ? array : arrayToSearch;
	}
	ngAfterViewInit() {}

	ngOnInit(): void {
		jQuery(document).scrollTop(0);

		if (document.location.pathname.indexOf("/search") != -1) {
			this.isExpanded = false;
		} else {
			this.isExpanded = true;
		}
	}

	public displayResultsWith(keywords) {
		this.isExpanded = false;
	}
}
