import {
	Component,
	HostListener,
	OnInit,
	Input,
	Output,
	EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Response } from "@angular/http/src/static_response";
import { WordService } from "../services/wordService";
import { geoCoordinatesItem } from "../global/utils/geoCoordinates";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "home",
	templateUrl: "../templates/home.html",
	providers: [],
	styleUrls: ["../css/home.css"],
})
export class home implements OnInit {
	@Output() public words: any = [];
	public isExpanded = false;

	constructor(public fb: FormBuilder, private parentRouter: Router) {}
	public ngOnInit() {
		this.isExpanded = true;
	}
}
