import { Injectable, Inject} from '@angular/core';
import { URLSearchParams, Http, Headers} from '@angular/http';
import { userItem } from '../../models/auth/user';
import { loginItem } from '../../models/auth/login';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import * as constants from '../../global/constants';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable()
@Inject(Http)
export class fileService {
    public currentUser:userItem;
    private http:Http;

    _users:userItem[] = [];
    headers:Headers = new Headers();

    constructor(http:Http) {
        this.http = http;
    }

}