import { Component, AfterViewInit } from "@angular/core";
import "rxjs/add/operator/map";
import { Router } from "@angular/router";
import { ScrollSpyService } from "ngx-scrollspy";
import * as jQuery from "jquery";

@Component({
	selector: "app-root",
	templateUrl: "./templates/app.html",
	providers: [ScrollSpyService],
	styleUrls: ["./css/app.component.css", "./css/webflow.css"],
})
export class AppComponent implements AfterViewInit {
	title = "app";
	isExpanded: boolean = false;

	constructor(
		public router: Router,
		private scrollSpyService: ScrollSpyService
	) {
		this.router.events.subscribe((event: any) => {
			//console.log(event.url)

			if (
				document &&
				document.location &&
				document.location.pathname &&
				document.location.pathname.indexOf("/search") != -1
			) {
				this.isExpanded = false;
			}
			// Working Homescreen on Back Button
			if (event.url == "/") {
				jQuery(".bor-header-wrapper").addClass("expanded");
				jQuery(".bor-press").css("display", "flex");
				jQuery("#searchPhrase").val("");
			} else if (
				event &&
				event.url &&
				(event.url.indexOf("fbclid") != -1 || event.url.indexOf("gclid") != -1)
			) {
				jQuery(".bor-header-wrapper").addClass("expanded");
				jQuery(".bor-press").css("display", "flex");
				jQuery("#searchPhrase").val("");
			} else {
				jQuery(".bor-header-wrapper").removeClass("expanded");
			}
		});
	}

	private ngOnInit() {
		// Preloader
		function preload(arrayOfImages) {
			jQuery(arrayOfImages).each(function () {
				new Image().src = this;
			});
		}

		preload([
			"../assets/img/valentino-funghi-146040.jpg",
			"../assets/img/Asset-watev.jpg",
			"../assets/img/mariana-vusiatytska-141230.jpg",
			"../assets/img/ian-dooley-281855.jpg",
			"../assets/img/william-stitt-162607.jpg",
		]);

		jQuery("body").css("opacity", 0);
		setTimeout(function () {
			jQuery("body").css("opacity", 1).addClass("animated fadeIn");
		}, 500);
	}

	ngAfterViewInit() {}
}
