import { WordItem } from "../models/word";
import { NewWordItem } from "../models/newWords";
import { Injectable, Inject } from "@angular/core";
import { Http, Headers, URLSearchParams } from "@angular/http";
import * as constants from "../global/constants";
import { Observable } from "rxjs/Rx";

@Injectable()
@Inject(Http)
export class WordService {
	_words: WordItem[] = [];
	new_words: NewWordItem[] = [];
	headers: Headers = new Headers();
	private http: Http;
	constructor(http: Http) {
		this.http = http;
		this.headers.append("Content-Type", "application/json");
	}
	// convert object to query string
	private convertObjectToQueryString(inputObject) {
		var str = "";
		for (var key in inputObject) {
			if (
				!inputObject.hasOwnProperty(key) ||
				typeof inputObject[key] === "function"
			) {
				continue;
			}
			if (typeof inputObject[key] === "object") {
				str += this.convertObjectToQueryString(inputObject[key]);
			} else {
				str += key + "=" + encodeURIComponent(inputObject[key]) + "&";
			}
		}

		return str;
	}

	public findMatchingRhymesByWord(
		word: String,
		useSlant: boolean
	): Observable<any> {
		this._words = [];
		let query = { word: word };
		return this.http
			.get(
				constants.dataServerURL +
					"?" +
					this.convertObjectToQueryString(query) +
					(useSlant ? "slant=1" : "slant=0")
			)
			.map((response: any) => {
				return response.json();
			})
			.map((response) => {
				response.words.slantRhymes.forEach((word) => {
					let wordItem = new WordItem(
						"",
						word.exactRhymeGroup,
						word.soundRhymeGroup,
						word.word,
						word.wordSyllables
					);
					this._words.push(wordItem);
				});
				return this._words;
			});
	}

	public addWordDB(word: String, useSlant: boolean): Observable<any> {
		this._words = [];
		let query = { word: word };
		return this.http
			.get(
				constants.dataAddURL +
					"?" +
					this.convertObjectToQueryString(query) +
					(useSlant ? "slant=1" : "slant=0")
			)
			.map((response: any) => {
				return response;
			});
	}

	public getSuggestion(word: String, useSlant: boolean) {
		this._words = [];
		let query = { word: word };
		return this.http
			.get(
				constants.getSuggest +
					"?" +
					this.convertObjectToQueryString(query) +
					(useSlant ? "slant=1" : "slant=0")
			)
			.map((response: any) => {
				return response;
			});
	}

	public getnewWords() {
		this.new_words = [];
		return this.http.get(constants.getnewWords).map((response: any) => {
			return response;
		});
	}
}
