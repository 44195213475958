
export const GoogleMapApi = 'AIzaSyBouJ4PEK64Wle5UsmDuRFDUepbI90cxvQ';
export const dataServerURL = "https://bor-service.herokuapp.com/GetResults.json";
export const serverRootURL = "https://bor-service.herokuapp.com/";
export const dataAddURL = "https://bor-service.herokuapp.com/AddWords";
export const getSuggest = "https://bor-service.herokuapp.com/getSuggestions";
export const getnewWords = "https://bor-service.herokuapp.com/AddedWords";
// export const dataServerURL = "http://localhost:8080/first/GetResults.json";
// export const serverRootURL = "http://localhost:8080/first";
// export const dataAddURL = "http://localhost:8080/first/AddWords";
// export const getSuggest = "http://localhost:8080/first/getSuggestions";
// export const getnewWords = "http://localhost:8080/first/AddedWords";

export const mailgunUrl: string = "ceigon.mailgun.org";
export const mailgunApiKey = 'key-1kibpgux576d3jfho4w3esua0woegc71';

export const states:Array<string> = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
    'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
    'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
    'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
    'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
    'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
    'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];