import {
	Component,
	EventEmitter,
	Injectable,
	Output,
	Input,
	OnInit,
} from "@angular/core";
import { userItem } from "../../models/user";
import { userService } from "../../services/auth/userService";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { isSuccess } from "@angular/http/src/http_utils";
import { userItem as UserItem } from "../../models/user";

@Component({
	selector: "register",
	templateUrl: "../../templates/account/register.component.html",
	providers: [userService],
	styleUrls: ["../../css/register.css"],
})
export class register implements OnInit {
	@Input() hiddenParent: boolean;
	@Output() hidden: boolean = false;
	@Output() relayRegister: EventEmitter<Boolean> = new EventEmitter<Boolean>();
	@Output() parentHidden: EventEmitter<Boolean> = new EventEmitter<Boolean>();
	@Output() notifyRegister: EventEmitter<UserItem> =
		new EventEmitter<UserItem>();
	@Output() public isExpanded = false;
	public user: userItem = new userItem(null, null, null, null);
	private isSuccess = false;
	private message = "";

	public registerForm = this.fb.group({
		username: ["", Validators.required],
		password: ["", Validators.required],
		firstName: ["", Validators.required],
		lastName: ["", Validators.required],
	});

	model = {
		firstName: "",
		lastName: "",
		username: "",
		password: "",
	};

	public ngOnInit() {}
	constructor(
		public fb: FormBuilder,
		private userService: userService,
		private router: Router
	) {
		document.body.style.backgroundColor = "#fff";
	}

	public hideParent() {
		this.hidden = true;
		this.notifyRegister.emit(this.user);
	}
	public doRegister() {
		this.user.firstName = this.registerForm.value.firstName;
		this.user.lastName = this.registerForm.value.lastName;
		this.user.username = this.registerForm.value.username;
		this.user.password = this.registerForm.value.password;
		this.userService.register(this.user).subscribe(
			(res) => {
				this.isSuccess = res.success;
				if (this.isSuccess) {
					this.message = "Thanks for joining Book of Rhymes";
				} else {
					this.message =
						"Oops! Something went wrong check the information you provided and try again";
				}

				// this.router.navigateByUrl("/login");
			},
			(err) => {
				this.message =
					"Oops! Something went wrong check the information you provided and try again";
			}
		);
	}
	public goLogin() {
		this.router.navigateByUrl("/login");
	}
}
