
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { AppComponent } from './app.component';;
import { header } from './components/global/header.component';
import { home } from './components/home.component';
import { resultPage } from './components/resultPage.component';
import { newWords } from './components/newWords.component';
import { login } from './components/account/login.component';
import { register } from './components/account/register.component';

export const router: Routes = [
    { path: '', component: home},
    { path: 'search/:keywords', component:resultPage},
    { path: 'signin', component:login},
    { path: 'signup', component:register},
    { path: 'addedWords', component:newWords}
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router);