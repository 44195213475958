import {
	Component,
	HostListener,
	OnInit,
	Input,
	Output,
	EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router, Params, UrlSegment } from "@angular/router";
import { Response } from "@angular/http/src/static_response";
import { WordService } from "../services/wordService";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { WordItem } from "../models/word";

@Component({
	selector: "search-results",
	templateUrl: "../templates/result.component.html",
	providers: [WordService],
	styleUrls: ["../css/search.css"],
})
export class resultPage implements OnInit {
	@Output() public notify: EventEmitter<any> = new EventEmitter<any>();
	public words: any = [];
	public sugesstedWords: any = [];
	public orderedResults: any = [];
	public syllabalArray: any = [];
	public isLoaded = false;
	public haveWords = false;
	public isSuccess = false;
	public IsSugesstedWord = false;
	public IsHomoph = false;
	@Output() public isExpanded = false;

	public id: String;
	public searchPhrase = "";
	public name = "";
	public searchPhrase1: FormControl = new FormControl();
	public rslt = "";
	public searchForm = this.fb.group({
		searchPhrase: ["", Validators.nullValidator],
	});
	private sub: any;

	constructor(
		private wordService: WordService,
		public fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router
	) {
		document.body.style.backgroundColor = "#1a1919";
		let urlSegments: Array<UrlSegment>;
		let params;
		this.route.url.subscribe((segments) => {
			urlSegments = segments;
			params = this.arrayFromPropOfArray(urlSegments, "path");
		});

		if (params.indexOf("search") != -1) {
			this.isExpanded = false;

			// Hide Press Bar on Search Results - Prev Dev set up all search content in header
			jQuery(".bor-press").css("display", "none");
		} else {
			this.isExpanded = true;
		}

		this.sub = this.route.params.subscribe((params) => {
			this.searchPhrase = params["keywords"];
			this.name = params["keywords"];
			this.getWordsByKeywords(this.name);
		});
	}

	public ngOnInit() {
		jQuery(document).scrollTop(0);
	}

	public getSyllableLabelFromArray(array) {
		if (array && array.length > 0) {
			if (array[0].wordSyllables == 1) {
				return array[0].wordSyllables + " Syllable";
			} else {
				return array[0].wordSyllables + " Syllables";
			}
		}
	}
	public updateSearchPhrase(event) {
		//this.searchPhrase = event;
	}
	private arrayFromPropOfArray(
		arrayToSearch: Array<any>,
		prop: any
	): Array<any> {
		let array = arrayToSearch.map(function (item) {
			return item[prop];
		});
		return array.indexOf(undefined) == -1 ? array : arrayToSearch;
	}
	public getWordsByKeywords(keywords): void {
		this.words = [];
		this.orderedResults = [];
		this.isLoaded = false;
		this.IsHomoph = false;
		this.haveWords = false;
		let result = this.wordService
			.findMatchingRhymesByWord(keywords, true)
			.subscribe(
				(res) => {
					this.IsSugesstedWord = false;
					this.sugesstedWords = "";
					this.isLoaded = true;
					this.haveWords = true;
					this.words = res;
					this.syllabalArray = this.arrayFromPropOfArray(
						this.words,
						"wordSyllables"
					);
					this.syllabalArray = this.syllabalArray.filter(function (
						elem,
						index,
						self
					) {
						return index == self.indexOf(elem);
					});
					this.syllabalArray.forEach((syllables: number) => {
						this.orderedResults[syllables] = this.words.filter(function (word) {
							return word.wordSyllables == syllables;
						});
					});
				},
				(err) => {
					this.words = [];
					this.isLoaded = true;
					this.haveWords = false;
				}
			);
	}

	// ----------------------------------------------------

	// Set up search function from search component

	// @Output() public notify: EventEmitter<any> = new EventEmitter<any>();
	// //@Output() public words:any = [];
	// @Input() public headerIsExpanded:boolean

	// public id:String;
	// public searchPhrase = "";
	// @Output() public localStyle = {top:'0px'};
	// public searchForm = this.fb.group({
	//     searchPhrase: ["", Validators.nullValidator],
	// });
	// constructor(private wordService:WordService,
	//             public fb: FormBuilder,
	//             private router:Router) {
	// }
	// public ngOnInit() {
	//     jQuery(document).scrollTop(0);
	// }
	// public updateSearchPhrase(event){
	// }
	// private arrayFromPropOfArray(arrayToSearch:Array<any>,prop:any):Array<any>{
	//     let array = arrayToSearch.map(function(item) { return item[prop]; });
	//     return (array.indexOf(undefined) == -1)?array:arrayToSearch;
	// }

	public doSearch(event, clickedValue) {
		this.isSuccess = false;
		this.IsHomoph = false;
		this.searchPhrase = this.searchForm.value.searchPhrase.trim();
		var st = this.name.trim();
		if (st != "") {
			this.IsSugesstedWord = false;
			this.sugesstedWords = "";
			//this.notify.emit(this.searchPhrase)
			//this.getWordsByKeywords(this.searchPhrase)
			this.router.navigate(["/search/", st]);
		}
		if (clickedValue) {
			jQuery("input").val("");
			this.router.navigate(["/search/", clickedValue]);
		}
		jQuery(document).scrollTop(0);
	}

	public onSearchChange(searchValue: string) {
		if (searchValue.trim() == "abuse") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "obtuse";
			this.sugesstedWords[1] = "use";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "bow") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "snow";
			this.sugesstedWords[1] = "wow";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "close") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "dose";
			this.sugesstedWords[1] = "rose";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "dove") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "stove";
			this.sugesstedWords[1] = "love";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "excuse") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "obtuse";
			this.sugesstedWords[1] = "use";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "expose") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "San Jose";
			this.sugesstedWords[1] = "rose";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "lead") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "head";
			this.sugesstedWords[1] = "plead";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "live") {
			this.sugesstedWords = [];
			this.sugesstedWords[0] = "give";
			this.sugesstedWords[1] = "drive";
			this.IsHomoph = true;
			this.IsSugesstedWord = false;
		} else if (searchValue.trim() == "minute") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "minute";
			this.sugesstedWords[1] = "cute";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "misread") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "head";
			this.sugesstedWords[1] = "plead";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "proofread") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "plead";
			this.sugesstedWords[1] = "head";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "read") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "San Jose";
			this.sugesstedWords[1] = "flume";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "resume") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "snow";
			this.sugesstedWords[1] = "wow";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "sewer") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "fewer";
			this.sugesstedWords[1] = "sewer";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "use") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "obtuse";
			this.sugesstedWords[1] = "use";
			this.IsHomoph = true;
		} else if (searchValue.trim() == "wind") {
			this.sugesstedWords = [];
			this.IsSugesstedWord = false;
			this.sugesstedWords[0] = "wind";
			this.sugesstedWords[1] = "kind";
			this.IsHomoph = true;
		}
		// else{
		//     this.sugesstedWords = [];
		//     this.IsHomoph = false;
		//     var resultString = "";
		//     if(searchValue.trim().length > 0){
		//         let result = this.wordService.getSuggestion(searchValue.trim(),true).subscribe(
		//             res => {
		//                 resultString = res._body;
		//                 resultString = resultString.substring(1, resultString.length-1);
		//                 var resss = resultString.split(",");
		//                 if(this.IsHomoph == false && this.name != ""){
		//                     this.sugesstedWords = resss;
		//                     this.IsSugesstedWord = true;
		//                     this.IsHomoph = false;
		//                 }
		//             },
		//             err => {
		//                 this.sugesstedWords = [];
		//                 this.IsSugesstedWord = false;
		//                 this.IsHomoph = false;
		//             }
		//         );
		//     }
		//     else{
		//         this.sugesstedWords = [];
		//         this.IsSugesstedWord = false;
		//         this.IsHomoph = false;
		//     }
		// }
	}

	public addWords(word): void {
		this.rslt = "";
		this.isLoaded = false;
		this.IsHomoph = false;
		this.haveWords = false;
		let result = this.wordService.addWordDB(word, true).subscribe(
			(res) => {
				this.isLoaded = true;
				this.haveWords = false;
				this.isSuccess = true;
				this.IsSugesstedWord = false;
				this.sugesstedWords = "";
				this.rslt = res;
			},
			(err) => {
				this.rslt = "Sorry something went wrong";
				//console.log("sss")
				this.isLoaded = true;
				this.haveWords = false;
				this.IsSugesstedWord = false;
				this.sugesstedWords = "";
			}
		);
	}

	// onKey(e) {
	//     if (e.keyCode == 13) {
	//         // let activeElement = <HTMLElement>document.activeElement;
	//         // activeElement && activeElement.blur && activeElement.blur();
	//         this.searchPhrase = this.searchForm.value.searchPhrase.trim();
	//         var st = this.name.trim();
	//         this.name = "";
	//         if (st != "") {
	//             this.IsSugesstedWord = false;
	//             this.IsHomoph = false;
	//             this.router.navigate(['/search/', st]);
	//         }
	//     }
	// }

	public onKey(event, clickedValue) {
		if (event.keyCode == 13) {
			this.isSuccess = false;
			this.IsHomoph = false;
			this.searchPhrase = this.searchForm.value.searchPhrase.trim();
			var st = this.name.trim();
			if (st != "") {
				this.IsSugesstedWord = false;
				this.sugesstedWords = "";
				//this.notify.emit(this.searchPhrase)
				//this.getWordsByKeywords(this.searchPhrase)
				this.router.navigate(["/search/", st]);
			}
			if (clickedValue) {
				jQuery("input").val("");
				this.router.navigate(["/search/", clickedValue]);
			}
			jQuery(document).scrollTop(0);
		}
	}

	public AddWord(event, clickedValue) {
		if (this.searchPhrase != "") {
			this.IsSugesstedWord = false;
			this.sugesstedWords = "";
			this.addWords(this.searchPhrase);
			this.IsHomoph = false;
		}
	}

	public changeText(value: string) {
		if (this.searchPhrase != "") {
			this.name = value.trim();
			this.IsSugesstedWord = false;
			this.sugesstedWords = "";
			this.IsHomoph = false;
		}
	}
}
