import { userItem as UserItem } from "../../models/user";
import { loginItem } from "../../models/auth/login";
import { Injectable, Inject } from "@angular/core";
import { Http, Headers, URLSearchParams } from "@angular/http";
import * as constants from "../../global/constants";
import { Observable } from "rxjs/Rx";

@Injectable()
@Inject(Http)
export class userService {
	headers: Headers = new Headers();
	public user: UserItem;
	private http: Http;
	constructor(http: Http) {
		this.http = http;
		this.headers.append("Content-Type", "application/json");
	}
	// convert object to query string
	private convertObjectToQueryString(inputObject) {
		var str = "";
		for (var key in inputObject) {
			if (
				!inputObject.hasOwnProperty(key) ||
				typeof inputObject[key] === "function"
			) {
				continue;
			}
			if (typeof inputObject[key] === "object") {
				str += this.convertObjectToQueryString(inputObject[key]);
			} else {
				str += key + "=" + encodeURIComponent(inputObject[key]) + "&";
			}
		}

		return str;
	}
	public register(user: UserItem): Observable<any> {
		let query = {
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			password: user.password,
		};
		return this.http
			.get(
				constants.serverRootURL +
					"signup?" +
					this.convertObjectToQueryString(query)
			)
			.map((response: any) => {
				return response.json();
			})
			.map((response) => {
				return response;
			});
	}
	public login(login: loginItem): Observable<any> {
		let query = { username: login.username, password: login.password };
		return this.http
			.get(
				constants.serverRootURL +
					"?signin" +
					this.convertObjectToQueryString(query)
			)
			.map((response: any) => {
				return response.json();
			})
			.map((response) => {
				return response;
			});
	}
}
