export class WordItem {
    constructor(public id:string,
                public exactRhymeGroup:string,
                public soundRhymeGroup:string,
                public word:string,
                public wordSyllables?:number) {

    }
    public toString(){
        return JSON.stringify(this);
    }
}