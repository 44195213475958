import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router, Params} from '@angular/router';

@Component({
    selector: 'foot',
    templateUrl: '../../templates/global/footer.html',
    providers:[],
    styleUrls: [
        '../../css/footer.css'
    ],
})

export class footer implements OnInit{
    public isAuthMaskVisible = false;
    private _router:Router;
    constructor(){

    }

    public ngOnInit() {
        //this.autoResizeDiv();
    }
}