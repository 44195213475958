import { Component, OnInit } from "@angular/core";
import { WordService } from "../services/wordService";
import { NewWordItem } from "../models/newWords";

@Component({
	selector: "app-new-words",
	templateUrl: "../templates/newWords.component.html",
})
export class newWords implements OnInit {
	newWords: NewWordItem[] = [];

	constructor(private wordService: WordService) {}

	ngOnInit(): void {
		this.wordService.getnewWords().subscribe((response: any) => {
			this.newWords = response.json();
		});
	}
}
