import { Component, EventEmitter, Injectable, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { loginItem } from '../../models/auth/login';
import { fileService } from "../../services/global/fileService";
import { userService } from "../../services/auth/userService";
import { FormBuilder, Validators } from '@angular/forms';
@Injectable()
@Component({
    selector: 'login',
    templateUrl: '../../templates/account/login.component.html',
    providers: [fileService],
    styleUrls: ['../../css/login.css']
})

export class login implements OnInit {
    @Input() hiddenParent: boolean;
    @Output() relayRegister: EventEmitter<Boolean> = new EventEmitter<Boolean>();
    @Output() parentHidden: EventEmitter<Boolean> = new EventEmitter<Boolean>();
    @Output() public isExpanded = false;
    private loginItem: loginItem = new loginItem(null, null);

    public loginForm = this.fb.group({
        username: ["", Validators.required],
        password: ["", Validators.required]
    });
    public hidden = false;
    public hideNav = false;
    constructor(public fb: FormBuilder, private fileService: fileService,
        private userService: userService, private router: Router) {

        document.body.style.backgroundColor = "#fff";
        if (router.url === '/login') {
            this.hideNav = true;
        }

    }
    public ngOnInit() {
    }
    model = {
        username: "",
        password: ""
    }
    public goRegister() {
        //this.relayRegister.emit(true);
        this.hideParent();
        this.router.navigateByUrl("/register");
    }
    public doLogin(event) {
        this.loginItem.username = this.loginForm.value.username;
        this.loginItem.password = this.loginForm.value.password;
        this.userService.login(this.loginItem).subscribe(
            res => {
                this.hideParent();
                this.router.navigateByUrl("/");
            },
            err => {
            }
        );
    }

    public hideParent() {
        this.hidden = true;
        this.parentHidden.emit(this.hidden);
    }
}
