import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { routes } from "./routes";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Response } from "@angular/http/src/static_response";
import { FadingCircleComponent } from "ng-spin-kit";

//components;
import { header } from "./components/global/header.component";
import { footer } from "./components/global/footer.component";
import { WordSearch } from "./components/wordSearch.component";
import { home } from "./components/home.component";
import { resultPage } from "./components/resultPage.component";
import { newWords } from "./components/newWords.component";
import { login } from "./components/account/login.component";
import { register } from "./components/account/register.component";
import { userService } from "./services/auth/userService";
import {
	ScrollSpyModule,
	ScrollSpyService,
	ScrollSpyAffixDirective,
} from "ngx-scrollspy";

@NgModule({
	declarations: [
		AppComponent,
		header,
		footer,
		WordSearch,
		home,
		resultPage,
		newWords,
		login,
		register,
		FadingCircleComponent,
		ScrollSpyAffixDirective,
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		HttpClientModule,
		routes,
		NgbModule.forRoot(),
		ScrollSpyModule.forRoot(),
	],
	providers: [userService, ScrollSpyService],
	bootstrap: [AppComponent],
})
export class AppModule {}
